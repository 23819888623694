@import '../../../appVariables';

.video-container {
  width: 160px;
  position: relative;
  margin-right: $spacer;
  flex-shrink: 0;

  video {
    width: 100%;
    height: 284px;
    object-fit: cover;
  }
}

.add-more-card {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: rgb(0 212 138 / 10%);
  border: 1px dashed #00d48a;
  height: 284px;
  width: 160px;
}

