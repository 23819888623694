@use 'sass:map';
@import '../../appVariables';

.auth-layout-container {
  display: flex;
  flex: 1;
  .img-container {
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1 0 0;
    background: url(../../assets/images/login-bg-stripes.png);
    background-repeat: no-repeat;
    background-color: #1163f9;
    background-size: cover;
    background-position: center;

    .screenshot {
      position: absolute;
      top: 50%;
      left: 50%;
      height: 50%;
    }
  }

  .auth-layout-content {
    background-color: $white;
    padding: map.get($spacers, 4);
    flex: 1 0 0;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}
@include media-breakpoint-down(sm) {
  .auth-layout-container {
    flex-direction: column;
    .auth-layout-content {
      padding: map.get($spacers, 3);
    }
  }
}
