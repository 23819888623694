@use 'sass:map';
@import '../../../appVariables';

.table .badge {
  font-weight: normal !important;
}


.top-bar {
  .top-bar-tab-btns {
    .btn.disabled {
      opacity: var(--bs-btn-disabled-opacity);
      border: none;
    }

    .btn {
      transition: none;
      font-weight: 600;
      margin: 0 12px;
      display: inline-flex;
      align-items: center;
      //opacity: 0.7;
      color:$gray-text;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }

      &:active,
      &:focus,
      &:hover {
        border-color: transparent;
        color: var(--bs-primary);
        background-color: transparent;
        outline: none;
        box-shadow: none;
      }

      &.active {
        opacity: 1;
        border: none;
        border-bottom: 3px solid var(--bs-primary);
        border-radius: 0;
        height: $topbar-height - 8;
        color: var(--bs-primary);
      }
    }
  }
  .game-select {
    display: none;
  }
}





@include media-breakpoint-down(lg) {
  .top-bar {
    .game-select {
      display: block;
    }
    .game-wrapper {
      display: flex;
      justify-content: space-between;
      .btn {
        white-space: nowrap;
      }
    }
  }
}