@import 'appVariables';

:root {
  --bs-light-400: #{$light-400};
  --bs-primary: #{$primary};
  --bs-dark-300: #{$dark-300};
  --bs-modal-bg: #{$modal-content-bg};
  --bs-modal-color: #{$modal-content-color};
  --bs-primary-100: #{$primary-100};
  --bs-primary-200: #{$primary-200};
  --bs-primary-300: #{$primary-300};
  --bs-dark-400: #{$dark-400};
  --bs-pale-white: #{$bg-white};
  --bs-gray-blue: #{$gray-blue};
  --bs-sidebar-color: #{$sidebar-color};
  --bs-form-check-bg: #{$form-check-input-bg};
  --bs-form-disable-bg: #{$form-select-disabled};
}

@include color-mode(dark) {
  --bs-pale-white: #37445b;
  --bs-white-rgb: 38, 44, 57;
  --bs-light-rgb: 26, 33, 44;
  --bs-primary-rgb: 0, 158, 255;
  --bs-light-400: #1a212d;
  --bs-primary: #009eff;
  --bs-dark-300: #{$white};
  --bs-primary-100: #1c2436;
  --bs-dark-400: #bac5d4;
  --bs-sidebar-color: #262c39;
  --bs-gray-blue: #{$gray-blue-dark};
  --bs-black-rgb: 255, 255, 255;
  --bs-form-disable-bg: #343a40 !important;

  .modal-content {
    --bs-modal-color: #{$white};
    --bs-modal-bg: #262c39;
  }
  .nav-item .dropdown .form-select {
    background-color: #262c39;
  }
  .input-group .login-input {
    background-color: #323d55;
    border-left: none;
  }
  .form-control,
  .form-select {
    background-color: #{$body-bg-dark};
  }
  //.form-control:disabled,
  //.form-select:disabled,
  //.input-group .form-control:disabled ~ .input-group-text,
  //.input-group .first-child {
  //  --bs-form-disable-bg: #343A40 !important;
  //}

  .form-check-input {
    --bs-form-check-bg: #262c39;
  }
  .form-select:disabled {
    background-color: #363d4b;
  }

  label.form-label {
    color: #{$white};
  }

  .table {
    --bs-table-bg: 255, 0, 0;
    --bs-table-hover-bg: #1e2431;
    --bs-table-hover-color: #{$white};
    --bs-table-color: #{$white};
    --bs-gray-blue: #{$gray-blue-dark};

    tbody tr td:not(:first-child) {
      color: #{$primary-100};
    }

    &.table-hover tbody tr {
      &:hover,
      &.active {
        .badge.text-dark-400 {
          background-color: var(--bs-sidebar-color);
          border: 1px solid #35455d;
        }
      }

      .badge.text-dark-400 {
        background-color: #{$body-bg-dark};
      }
    }
  }

  .rdrDateDisplayWrapper {
    background-color: #25395d;
  }

  .rdrNextPrevButton {
    background-color: #{$body-bg-dark};
    color: #{$white};
  }

  .rdrMonthAndYearPickers select {
    color: #{$white};
  }

  .rdrDayDisabled,
  .search,
  .rdrDateDisplayItem,
  .input-group > span,
  .tags {
    background-color: #{$body-bg-dark};
  }

  .rdrDayNumber span {
    color: #{$white};
  }

  .react-datepicker__header {
    background-color: #1c2436;
  }

  .react-datepicker__month {
    background-color: #262c39;
    margin: 0;
  }

  .react-datepicker__current-month {
    color: #{$white};
  }

  .react-datepicker__day-name {
    color: #{$white};
  }

  .btn-text {
    color: #{$gray-text};

    &.active,
    &:hover {
      background-color: #{$dark};
      color: #{$dark-200};
    }
  }

  .badge.text-dark-400 {
    background-color: rgba(17, 21, 40, 0.68);
    color: #{$dark-400-dark};
  }

  .table.tap-n-table .light-button,
  .badge.light-button {
    background-color: rgba(17, 21, 40, 0.68);
    color: #{$white};
  }

  .btn-light {
    color: #{$white};
  }

  .send-button {
    border: 1px solid #242f44;
  }
  .react-datepicker__day {
    color: #{$white};

    &:hover {
      background-color: #{$dark-700};
    }
  }
  .react-datepicker__day--disabled {
    color: #{$gray-blue};
  }
  .rdrCalendarWrapper {
    background-color: rgba(17, 21, 40, 0.68);
  }
}
