@use 'sass:map';

@import 'appVariables';

// Layout & components
@import 'bootstrap/scss/root';
@import 'bootstrap/scss/reboot';
@import 'bootstrap/scss/type';
@import 'bootstrap/scss/images';
@import 'bootstrap/scss/containers';
@import 'bootstrap/scss/grid';
@import 'bootstrap/scss/tables';
@import 'bootstrap/scss/forms';
@import 'bootstrap/scss/buttons';
@import 'bootstrap/scss/transitions';
@import 'bootstrap/scss/dropdown';
@import 'bootstrap/scss/button-group';
@import 'bootstrap/scss/nav';
@import 'bootstrap/scss/navbar';
@import 'bootstrap/scss/card';
@import 'bootstrap/scss/accordion';
@import 'bootstrap/scss/breadcrumb';
@import 'bootstrap/scss/pagination';
@import 'bootstrap/scss/badge';
@import 'bootstrap/scss/alert';
@import 'bootstrap/scss/progress';
@import 'bootstrap/scss/list-group';
@import 'bootstrap/scss/close';
@import 'bootstrap/scss/toasts';
@import 'bootstrap/scss/modal';
@import 'bootstrap/scss/tooltip';
@import 'bootstrap/scss/popover';
@import 'bootstrap/scss/carousel';
@import 'bootstrap/scss/spinners';
@import 'bootstrap/scss/offcanvas';
@import 'bootstrap/scss/placeholders';

// Helpers
@import 'bootstrap/scss/helpers';

// Utilities
@import 'bootstrap/scss/utilities/api';

///// DARK THEME /////
@import 'darkMode';

html,
body,
#root {
  margin: 0;
  padding: 0;
  width: 100vw;
  max-width: 100%;
  min-height: 100vh;
}

#root {
  display: flex;
}

.input-group .login-input {
  background-color: #e8f0fe;
}
@font-face {
  font-family: inter;
  src: url('./assets/fonts/Inter/Inter-VariableFont_slnt,wght.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: inter;
  src: url('./assets/fonts/Inter/static/Inter-Bold.ttf');
  font-weight: bold;
}
/* Works on Firefox */
* {
  scrollbar-width: thin;
}
*::-webkit-scrollbar-track {
  background: transparent;
}
:focus-visible {
  outline: none;
}

// utils
.z-4 {
  z-index: 4 !important;
}

.z-5 {
  z-index: 5 !important;
}

.outline-none {
  outline: none;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}
/// colors

.bg-none {
  background-color: unset !important;
}

.bg-pale-white {
  background-color: var(--bs-pale-white) !important;
}

.bg-success-300 {
  background-color: $success-300 !important;
}

.bg-danger-300 {
  background-color: $danger-300 !important;
}

.bg-warning-300 {
  background-color: $warning-300 !important;
}

.bg-primary-100 {
  background-color: var(--bs-primary-100) !important;
}
.bg-primary-200 {
  background-color: var(--bs-primary-200) !important;
}
.bg-primary-300 {
  background-color: var(--bs-primary-300) !important;
}
.bg-primary-400 {
  background-color: $primary-400 !important;
}

.bg-light-200 {
  background-color: $light-200 !important;
}

.bg-light-400 {
  background-color: var(--bs-light-400) !important;
}

.bg-dark-200 {
  background-color: $dark-200 !important;
}

.bg-dark-300 {
  background-color: $dark-300 !important;
}

.text-dark-400 {
  color: var(--bs-dark-400) !important;
}

.text-dark-300 {
  color: $dark-300 !important;
}

.text-gray-text {
  color: $gray-text !important;
}

.text-gray-blue {
  color: var(--bs-gray-blue) !important;
}

.text-bg-primary-light {
  background-color: $primary-100 !important;
  color: $primary !important;
}

// fill for svg icons
.text-warning {
  fill: $warning;
}

.text-success {
  fill: $success;
}

.text-danger {
  fill: $danger;
}

.fs-7 {
  font-size: $font-size-base * 0.875;
}

.fs-8 {
  font-size: $font-size-base * 0.75;
}

.fs-9 {
  font-size: $font-size-base * 0.5;
}

.no-resize {
  resize: none;
}

.flex-basis-0 {
  flex-basis: 0;
}

.img-fit-contain {
  object-fit: contain;
}

.img-fit-cover {
  object-fit: cover;
}

.list-hover {
  .list-item {
    &:hover {
      background-color: $primary-200 !important;
    }
  }
}

pre {
  margin: 0;
  white-space: pre-wrap;
  word-wrap: break-word;
}

// Buttons ----------------------------------------------------------------
.btn {
  svg {
    transition: fill 0.15s ease-in-out;
  }

  &:hover svg {
    fill: $black;
  }
}

.btn-success,
.btn-danger,
.btn-info {
  color: $white;
}

.btn-light {
  background-color: rgb(77 111 157 / 10%);
  border-color: transparent;
}

.btn-text {
  color: $gray-text;

  &.active,
  &:hover {
    background-color: $white-bg;
    color: $primary;
  }
}

.btn-primary:hover {
  color: $primary-200;

  &:hover svg {
    fill: $primary-200;
  }
}

// Modal ----------------------------------------------------------------
.modal-backdrop.show {
  z-index: 1055;
}

.modal-content {
  border: none;
}

// Alert ---------------------------
.alert-warning {
  color: $warning;
  & svg {
    fill: $warning;
  }
}

.alert-danger {
  & svg {
    fill: $danger-800;
  }
}

.alert-success {
  & svg {
    fill: $success-800;
  }
}

.invalid-tooltip {
  color: $white;
  font-size: 0.75rem;
}

// Pagination  ----------------------------------------------------------------
.pagination {
  border-radius: $border-radius-lg;
  padding: calc($spacer / 2.5);

  &.pagination-lg .page-link {
    font-size: 1rem;
  }

  .page-item {
    &.disabled .page-link {
      background-color: unset;
      opacity: 0.7;
    }

    .page-link {
      border-radius: $border-radius-lg;

      &:focus {
        color: var(--bs-pagination-color);
        background-color: var(--bs-pagination-bg);
        box-shadow: none;
      }
    }
  }
}

// Table  ----------------------------------------------------------------

.table {
  &.tap-n-table {
    th {
      vertical-align: middle;
    }

    .badge.text-dark-400 {
      background-color: $light-400;
      color: $dark-400;
      padding: map.get($spacers, 4) map.get($spacers, 1);
      min-width: 72px;
      text-align: center;
    }
    .light-button {
      background-color: #ccd8ff;
      color: $pagination-active-color;
    }
    tbody tr td:not(:first-child) {
      color: var(--bs-gray-blue);
    }
  }

  & thead,
  & tbody,
  & thead tr,
  & thead tr th,
  & tfoot tr,
  & tfoot tr th,
  & tfoot tr td,
  & tbody tr,
  & tbody tr td {
    background-color: inherit;
  }

  &.sticky-first-column tr > th:first-child,
  &.sticky-first-column tr > td:first-child {
    position: -webkit-sticky; /* Safari */
    position: sticky;
    left: 0;
    z-index: 1;
    background-color: inherit;
  }

  &.table-border-dashed {
    th,
    td {
      border-style: dashed;
    }
  }

  tbody tr {
    &:last-child td {
      border-bottom: none;
    }
  }
}
.table-hover tbody tr {
  position: relative;

  .badge.text-dark-400 {
    border: 1px solid transparent;
  }

  &:hover,
  &.active {
    & td:first-child::before {
      display: block;
      content: '';
      width: 3px;
      background: $primary;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }

    .badge.text-dark-400 {
      background-color: $white;
      border: 1px solid $dark-200;
    }
  }
}

// chart  ----------------------------------------------------------------
.json-metrics-charts {
  .recharts-legend-item {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
// Table data filters  ----------------------------------------------------------------
.data-filter-container {
  .nav-item {
    margin: map.get($spacers, 1);
  }

  select,
  input {
    max-width: 120px;
  }

  &:first-child {
    margin-left: 0;
  }

  .form-select,
  input:not([type='checkbox']) {
    color: var(--bs-dark-300);
    border: none;
  }

  .input-group-text {
    background-color: $white;
    border: none;
  }

  .input-group-text:has(~ .form-select:disabled) {
    background-color: var(--bs-secondary-bg);
  }
}

.recharts-wrapper svg {
  overflow: visible;
}

.no-arrow .dropdown-toggle::after {
  display: none;
}

.form-check {
  & label,
  & input {
    cursor: pointer;
  }
}

//.form-label {
//  font-size: 0.875rem;
//  color: $gray-blue;
//}

// input red
.checkbox-red input:checked[type='checkbox'] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m5 10 10 0 '/%3e%3c/svg%3e");
  background-color: $danger;
  border-color: $danger-700;
}

// icons in front of Input ----------------------------------------------------------------
.input-group:not(.has-validation) > :not(:last-child, .dropdown-toggle, .dropdown-menu, .form-floating),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3),
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control,
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select {
  border-right: none;
}

.input-group
  > :not(:first-child, .dropdown-menu, .valid-tooltip, .valid-feedback, .invalid-tooltip, .invalid-feedback) {
  border-left: none;
  background-clip: padding-box;
}

.input-group.has-validation > :nth-last-child(n + 3):not(.dropdown-toggle, .dropdown-menu, .form-floating),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n + 4),
.input-group.has-validation > .form-floating:nth-last-child(n + 3) > .form-control,
.input-group.has-validation > .form-floating:nth-last-child(n + 3) > .form-select {
  border-right: none;
  background-clip: padding-box;
}
.form-control:disabled,
.input-group-text:has(+ .form-control:disabled),
.form-select:disabled,
.input-group .form-control:disabled ~ .input-group-text {
  background-color: var(--bs-form-disable-bg) !important;
  border: none;
  cursor: not-allowed;
  background-clip: padding-box;
}
.input-group .input-group-text:first-child:has(+ .form-control:disabled) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group .react-datepicker-wrapper {
  display: flex;
  width: auto;
  flex-grow: 1;
  .form-control {
    border-right: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}
.form-check-input[type='checkbox'] {
  cursor: pointer;
}

// icon focus border
.input-group:focus-within .input-group-text {
  border-color: $input-focus-border-color !important;
}

.form-control.is-invalid ~ .input-group-text {
  border-color: var(--bs-form-invalid-border-color);
}

.login-form {
  max-width: 570px;
  width: 100%;
}

// TERMS AND CONDITION ----------------------------------------------------------------

.terms-and-conditions {
  max-width: 70vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  margin-top: 2em;
  color: #171717;
  text-transform: uppercase;
  font-size: 1em;
}

.terms-and-conditions h1 {
  margin-top: 1em;
}

.terms-and-conditions br {
  display: block;
  margin: 10px 0;
}

.terms-and-conditions .home-back-icon {
  text-align: right;
  padding: 20px 25px 0 0;
}

.terms-and-conditions table * {
  text-align: justify;
  color: #000 !important;
}

.terms-and-conditions .footer {
  width: 100%;
  height: 10em;
}

/// react-datepicker
.react-datepicker__header {
  background-color: #f0f5ff;
}

//// ---- EXTRA

.star-rating {
  --star-size: 40;
  --star-color: #4d6f9d;
  --star-background: #ffb565;
  --percent: calc(var(--rating) / 5 * 100%);

  display: inline-block;
  font-size: var(--star-size);
  font-family: Times; // make sure ★ appears correctly
  line-height: 1;

  &::before {
    content: '★★★★★';
    letter-spacing: 3px;
    background: linear-gradient(90deg, var(--star-background) var(--percent), var(--star-color) var(--percent));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

@include media-breakpoint-down(lg) {
  .data-filter-container {
    select,
    input,
    .dropdown {
      max-width: 100%;
      width: 100%;
    }
  }
}

.modal-animate .modal-dialog {
  transition:
    transform 0.3s ease-out,
    width 5s,
    max-width 0.3s !important ;
}

.threedots:after {
  content: "\2807";
  font-size: 30px;
  cursor: pointer;
}