@use 'sass:map';
@import '../../appVariables';

.react-pdf__Page__textContent,
.react-pdf__Page__annotations {
  display: none;
}

.pdf-view-container {
  display: flex;
  flex: 1 1 300px;
  border-radius: $border-radius-lg;
  background-color: $white;

  .react-pdf__Document {
    width: 100%;
    margin: $spacer;

    canvas.react-pdf__Page__canvas {
      height: auto !important;
      max-height: 100% !important;
      width: 100% !important;
      border-radius: $border-radius-lg;
      margin-bottom: $spacer;
    }
  }
}

.pdf-list {
  display: flex;
  flex-direction: column;
  max-height: 100%;
  overflow-y: scroll;
  scrollbar-color: black transparent;
  margin-right: map.get($spacers, 2);

  .pdf-list-item {
    display: flex;
    position: relative;
    padding: map.get($spacers, 2);
    border-radius: $border-radius-lg;
    background-color: $white;
    margin-bottom: map.get($spacers, 2);
    margin-right: map.get($spacers, 2);
    border: 2px solid transparent;
    cursor: pointer;
    width: 280px !important;
    height: 160px !important;

    &.active,
    &:hover {
      border-color: $info;
    }

    .item-title {
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: center;
      background-image: url('../../assets/logoTapNation/LogoTapNation40.png');
      border-radius: $border-radius-lg;
      background-size: auto 110%;
      background-repeat: no-repeat;
      background-color: #1362f9;
      background-position: -40px -17px;
      padding: $border-radius-lg;
    }
  }
}
.badge.light-button {
  background-color: #ccd8ff;
  color: $pagination-active-color;
}
@include media-breakpoint-down(lg) {
  .pdf-list {
    width: 100%;
    overflow-x: scroll;
    flex-direction: row;
    margin-right: 0;
  }
}
@include media-breakpoint-down(sm) {
  .show-details {
    flex-direction: column;
    margin: 0 auto;
  }
}
