@import '../../../appVariables';

.slider::-webkit-slider-runnable-track {
  background-color: $white !important;
}

.slider::-moz-range-track {
  background-color: $white !important;
}

.rdrMonth {
  margin: 0 auto;
  width: 100%;
}
