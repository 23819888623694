@use 'sass:map';
@import '../../appVariables';

#user-consent-confirmation {
  padding-left: $sidebar-width + 12;
  & > div {
    max-width: 100%;
    width: 95%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
  }
}

#root {
  .top-bar-fixed-cont {
    position: fixed;
    top: 0;
    left: 0;
    padding-left: $sidebar-width + 12;
    z-index: 9;
  }

  .top-bar {
    height: $topbar-height;

    .divider {
      border: 1px solid #eaeaeb;
      height: 32px;
    }

    .main-search {
      .input-group-text {
        border-color: transparent;
      }

      input {
        border-color: transparent;
        background-color: $white-bg;
        line-height: $font-size-base * 1.55;
        padding: calc($spacer / 2) $spacer calc($spacer / 2) calc($spacer / 4);

        &:focus {
          border-color: $input-focus-border-color;
        }
      }

      //background-color: $white-bg;
      border-radius: $border-radius;
    }
  }

  .sidenav {
    height: 100%;
    width: $sidebar-width;
    padding: 0;
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    background-color: var(--bs-sidebar-color);
    display: flex;
    flex-direction: column;

    .color-button {
      padding-left: 12px;
      svg {
        flex-shrink: 0;
        margin: 1px 0;
      }
      &:hover{
        background-color: #3c424d;
        color: $white;

        SVG {
          fill: white;
          color: white;
        }

        .color-text {
          color: white;
        }
      }
    }
    .personal-info {
      display: none;
    }

    .collapse-btn-wrapper {
      width: 20px;
      cursor: pointer;

      .navbar-button {
        background-color: $dark-400;
        position: relative;
        top: 24px;
        width: 18px;
        height: 18px;
        border-radius: 50%;
        display: none;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }

      &:hover .navbar-button {
        display: flex;
      }
    }

    .user-dropdown {
      cursor: pointer;

      .user-dropdown-tittle {
        display: none;
        width: 100%;
        color: #a4a3a5;
        font-weight: bold;
        font-size: 1rem;
        position: initial;
        margin-left: 12px;


        div {
          line-height: 1;
        }
      }

      svg {
        flex-shrink: 0;
        margin: 1px 0;
      }

      &.show .dropdown-toggle .arrow {
        rotate: 180deg;
      }
    }


    a {
      text-decoration: none;
    }

    .header {
      display: none;
    }

    /** Active link styles */
    .active.nav-item,
    .nav-link:hover,
    a.active .nav-link,
    a.active .nav-item,
    a.active .list-group .list-group-item,
    .list-group a .list-group-item:hover {
      border-radius: $border-radius;
      background: rgb(255 255 255 / 10%) !important;

      .nav-link-text {
        color: $white !important;
      }

      svg {
        fill: white;
      }
    }

    &.collapsed .active.nav-item {
      background-color: transparent !important;
    }

    .nav-link,
    .nav-item {
      cursor: pointer;
      position: relative;
      margin: 3px 12px;
      padding: 6px !important;

       div > svg, > svg {
        fill: rgb(255 255 255 / 60%);
      }

      &:hover .nav-link-text {
        display: block;
      }

      .nav-link-text {
        padding: 6px 14px;
        background: #323135;
        color: #a4a3a5;
        display: none;
        position: absolute;
        left: 46px;
        top: 4px;
        z-index: 100;
        border-radius: 6px;
        width: auto;
        white-space: nowrap;

        &::after {
          content: '';
          position: absolute;
          top: 0;
          bottom: 0;
          width: 20px;
          left: -16px;
        }

        &::before {
          content: '';
          position: absolute;
          left: -5px;
          top: 11px;
          width: 0;
          height: 0;
          border-top: 5px solid transparent;
          border-bottom: 5px solid transparent;
          border-right: 5px solid #323135;
        }

        .list-group a {
          text-decoration: none;
          border-radius: $border-radius;

          &.active {
            background: rgb(255 255 255 / 10%);

            .list-group-item {
              color: $white;
            }
          }
          .list-group-item {
            background-color: inherit;
            color: $white;
            border: none;
          }
        }
      }
    }

    .navbar-toggler:not(.collapsed) .navTrigger i {
      &:nth-child(1) {
        animation: inT 0.8s forwards;
      }

      &:nth-child(2) {
        animation: inM 0.8s forwards;
      }

      &:nth-child(3) {
        animation: inBtm 0.8s forwards;
      }
    }

    /* Animation */
    .navbar-toggler {
      box-shadow: none;
    }

    .navTrigger {
      cursor: pointer;
      width: 40px;
      margin: 4px 0;
      display: block;

      i {
        background-color: #fff;
        border-radius: 2px;
        content: '';
        display: block;
        width: 100%;
        height: 4px;

        &:nth-child(1) {
          animation: outT 0.8s backwards;
          animation-direction: reverse;
        }

        &:nth-child(2) {
          margin: 5px 0;
          animation: outM 0.8s backwards;
          animation-direction: reverse;
        }

        &:nth-child(3) {
          animation: outBtm 0.8s backwards;
          animation-direction: reverse;
        }
      }
    }
  }

  /* Style page content */
  .main-container {
    padding-left: $sidebar-width;
    display: flex;
    flex-direction: column;
    flex: 1;
    padding-top: $topbar-height;
    max-width: 100%;

    .top-bar {
      height: $topbar-height - 8;
    }
  }
}

body, .sidenav, .top-bar-fixed-cont, .main-container .top-bar{
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@include media-breakpoint-up(md) {
  #user-consent-confirmation,
  #root .main-container,
  #root .top-bar-fixed-cont {
    transition: padding 1s, background-color 0.15s ease-in-out;
  }
  #root .sidenav, .sidenav .navbar-brand{
    transition: width 1s;
  }

  #root {
    & .sidenav.collapsed ~ #user-consent-confirmation,
    & .sidenav.collapsed ~ .main-container,
    & .sidenav.collapsed ~ .top-bar-fixed-cont,
    & .sidenav.opening ~ #user-consent-confirmation,
    & .sidenav.opening ~ .main-container,
    & .sidenav.opening ~ .top-bar-fixed-cont,
    {
      padding-left: $sidebar-collapsed-width;
    }

    .sidenav {
      &.closing .active.nav-item,
      &.opening .active.nav-item{
        background-color: transparent !important;
      }
    }
    #main-navbar-nav {
      width: 100%;
      align-items: flex-start !important;
    }

    .sidenav {
      &.collapsed, &.opening, &.closing {
        width: $sidebar-collapsed-width;
        height: 100%;

        & .navbar-brand {
          width: calc($sidebar-collapsed-width - 20px);
        }

        .nav-link,
        .nav-item {
          display: flex;
          align-items: flex-start;
          padding: 0.5rem;

          .nav-link-text {
            display: block;
            background-color: transparent;
            color: #a4a3a5;
            font-weight: bold;
            font-size: 1rem;
            position: initial;
            line-height: 1;

            &::before {
              content: none;
            }

            .list-group-item {
              border: none;
              color: $white;
              background-color: transparent;
              padding: 6px 14px;
            }

            & a {
              transition: all 0.5s;
              & .list-group-item {
                color: #a4a3a5;
                font-weight: normal;

              }
            }

            .list-group-item:hover {
              .nav-link-text {
                color: white !important;
              }

              .list-group-item:hover {
                color: white !important;
              }
            }
          }
        }

        .user-dropdown-tittle {
          display: block;
        }

        .personal-info {
          display: none;
        }

        #main-navbar-nav {
          overflow-y: auto;
          overflow-x: hidden;
        }

        .header {
          display: block;
        }
      }
      &.closing {
        width: $sidebar-width;
        & .navbar-brand {
          display: flex;
          align-self: flex-start;
          padding: 16px 2px;
          margin: 0 10px 8px 10px;
          border-bottom: 2px solid rgb(255 255 255 / 10%);
          width: calc( $sidebar-width - 20px);

          .brand {
            overflow: hidden;
          }
        }
      }
        & .navbar-brand {
        display: flex;
        align-self: flex-start;
        padding: 16px 2px;
        margin: 0 10px 8px 10px;
        border-bottom: 2px solid rgb(255 255 255 / 10%);
        width: calc( $sidebar-width - 20px);

        .brand {
          overflow: hidden;
        }
      }

      &.closing,
      &.opening {
        .nav-item a{
          transition: height 1s;
          visibility: hidden;
          opacity: 0;
          height: 0;
        }
      }
      &.collapsed {
        .nav-item a{
          visibility: visible;
          opacity: 1;
          height: 28px;
        }
      }
    }

  }
}

@include media-breakpoint-down(md) {
  #root .sidenav .user-dropdown .user-dropdown-tittle {
    display: block;
  }
  #root {
    .collapse-btn-wrapper {
      display: none;
    }

    .userInfo {
      visibility: hidden;
    }

    .main-container {
      padding-left: 0;
      padding-top: $sidebar-height + $topbar-height;
    }

    .top-bar-fixed-cont {
      margin-top: $sidebar-height;
      padding-left: 12px;
    }

    .sidenav {
      width: 100%;
      height: $sidebar-height;
      flex-direction: row;
      .color-button {
        &:hover {
          background-color: #a4a3a5;
          color: #666666;
          SVG {
            fill: $white;
          }
          .color-text {
            color: $white;
          }
        }
      }


      .navbar-brand {
        padding: 0;
        margin: 12px 16px;
      }

      .navbar-collapse {
        background-color: white;
      }

      .header {
        display: block;
      }

      /** Active link styles */
      .nav-item .nav-link-text .list-group a.active .list-group-item,
      .active.nav-item,
      .nav-link:hover,
      a.active .nav-link,
      a.active .nav-item,
      .list-group a .list-group-item:hover {
        border-radius: $border-radius;
        background: rgb(0 0 0 / 10%) !important;
        .nav-link-text {
          color: $black !important;
        }

        svg {
          fill: $black !important;
        }
      }

      .collapsed {
        max-height: 500px;
        overflow-y: scroll;
      }

      .nav-link,
      .nav-item {
        display: flex;
        align-items: flex-start;
        padding: 0.5rem;


        svg {
          fill: rgb(0 0 0 / 60%) !important;
        }

        .nav-link-text {
          display: block;
          width: 100%;
          color: rgb(0 0 0 / 60%);
          font-weight: bold;
          font-size: 1rem;
          background: transparent;
          position: initial;

          & .list-group a .list-group-item {
            color: #a4a3a5;
          }

          &::before {
            content: none;
          }

          .list-group {
            padding-left: 8px;

            a.active {
              background: rgb(0 0 0 / 10%) !important;
            }
          }
        }
      }
    }
  }
}

@keyframes inM {
  50% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(45deg);
  }
}

@keyframes inM {
  50% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(45deg);
  }
}

@keyframes outM {
  50% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(45deg);
  }
}

@keyframes outM {
  50% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(45deg);
  }
}

@keyframes inT {
  0% {
    transform: translateY(0) rotate(0deg);
  }

  50% {
    transform: translateY(9px) rotate(0deg);
  }

  100% {
    transform: translateY(9px) rotate(135deg);
  }
}

@keyframes inT {
  0% {
    transform: translateY(0) rotate(0deg);
  }

  50% {
    transform: translateY(9px) rotate(0deg);
  }

  100% {
    transform: translateY(9px) rotate(135deg);
  }
}

@keyframes outT {
  0% {
    transform: translateY(0) rotate(0deg);
  }

  50% {
    transform: translateY(9px) rotate(0deg);
  }

  100% {
    transform: translateY(9px) rotate(135deg);
  }
}

@keyframes outT {
  0% {
    transform: translateY(0) rotate(0deg);
  }

  50% {
    transform: translateY(9px) rotate(0deg);
  }

  100% {
    transform: translateY(9px) rotate(135deg);
  }
}

@keyframes inBtm {
  0% {
    transform: translateY(0) rotate(0deg);
  }

  50% {
    transform: translateY(-9px) rotate(0deg);
  }

  100% {
    transform: translateY(-9px) rotate(135deg);
  }
}

@keyframes inBtm {
  0% {
    transform: translateY(0) rotate(0deg);
  }

  50% {
    transform: translateY(-9px) rotate(0deg);
  }

  100% {
    transform: translateY(-9px) rotate(135deg);
  }
}

@keyframes outBtm {
  0% {
    transform: translateY(0) rotate(0deg);
  }

  50% {
    transform: translateY(-9px) rotate(0deg);
  }

  100% {
    transform: translateY(-9px) rotate(135deg);
  }
}

@keyframes outBtm {
  0% {
    transform: translateY(0) rotate(0deg);
  }

  50% {
    transform: translateY(-9px) rotate(0deg);
  }

  100% {
    transform: translateY(-9px) rotate(135deg);
  }
}
