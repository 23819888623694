@import '../../../appVariables';

.config-wrapper {
  .info-wrapper {
    border-bottom: 1px solid #495057;
  }

  .info-wrapper:last-child {
    border-bottom: none;
  }

  .action-btn {
    padding: 10px 0;
  }

  .config-container {
    display: flex;
    gap: 5px;
  }

  .config-container > div:first-child {
    display: flex;
    flex-direction: column;
  }

  .config-container > div:last-child {
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-width: 500px;
    width: 100%;
  }

  .country-wrapper {
    max-width: 300px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
