// general
$font-family-base: 'inter', sans-serif;
$sidebar-width: 64px;
$sidebar-collapsed-width: 240px;
$sidebar-height: 56px;
$sidebar-color: #1B1A1D;
$topbar-height: 64px;
$white-bg: #f0f5ff;
$bg-white: #ccd2dc;
$gray-blue: #4d6f9d;
$gray-blue-dark: #B7C4D7;
$gray-text: #829ABA;
$body-bg: #f0f5ff;
$body-bg-dark: #1A212D;
$modal-content-bg: #fff;
$modal-content-color: $gray-blue;
$form-label-color: $gray-blue;
$input-bg: #FFF;
$form-select-disabled: #E9ECEF;

// Primary Color
$primary-100: #ccd8ff;
$primary-200: #99b0ff;
$primary-300: #6685ff;
$primary-400: #3f64ff;
$primary: #0829c4;
$primary-600: #0021db;
$primary-800: #001193;

// Success Color
$success-800: #009881;
$success-700: #00b688;
$success: #00d48a;
$success-500: #39e598;
$success-400: #60f2a2;
$success-300: #96fab9;
$success-200: #cafcd7;
$success-100: #cafcd7;

// Info Color
$info: #009eff;

// Warning Color
$warning-100: #fff8ea;
$warning-200: #fff1d6;
$warning-300: #ffe0ad;
$warning-400: #ffca83;
$warning-500: #ffb565;
$warning: #ff9232;
$warning-600: #db7024;
$warning-700: #b75219;

// Danger Color
$danger-100: #ffe1d8;
$danger-200: #ffe1d8;
$danger-300: #ffbcb2;
$danger-400: #ff8f8b;
$danger-500: #ff6f77;
$danger: #ff3f5c;
$danger-700: #db2e58;
$danger-800: #b71f53;

// Light Color
$light-100: #fbfcfe;
$light-200: #f8f9fd;
$light-400: #e8ebf7;
$light: #cfd3e6;

// Dark Color
$dark-100: #dce0f7;
$dark-200: #bbc2ef;
$dark-300: #8c95d0;
$dark-400: #5f67a1;
$dark: #2d3363;
$dark-600: #202655;
$dark-700: #161a47;
$dark-800: #0e1139;



// Global
$body-color: $dark;
$border-color: rgba($gray-blue, 0.2);
$input-border-color: $border-color;
$hr-border-color: $border-color;
$border-color-translucent: rgba($gray-blue, 0.1);
$table-bg: #fff;
$table-color: $body-color;
$table-hover-bg: $light-200;

// Pagination
$pagination-border-width: 0;
$pagination-active-color: $primary;
$pagination-active-bg: $primary-200;
$pagination-padding-y-sm: 1px !default;
$pagination-padding-x-sm: 0.5rem !default;
$pagination-padding-y-lg: 0.25rem !default;
$pagination-padding-x-lg: 0.75rem !default;

// Inputs
$input-group-addon-bg: transparent;
$input-transition: none;

// Buttons
$input-btn-font-size: 0.875rem;
$input-btn-font-size-sm: 0.75rem;
$input-btn-focus-box-shadow: none;
$form-select-focus-box-shadow: none;
$btn-disabled-opacity: 0.35;

// Accordion
$accordion-padding-y: 0.875rem;
$accordion-padding-x: 1rem;

// Alert
$alert-padding-x: 0.75rem;
$alert-padding-y: 0.75rem;

// Modal
$modal-inner-padding: 1.25rem;

///// DARK THEME /////
// general
$body-bg-dark: #1A212D;
$modal-content-bg: #fff;
$dark-400-dark: #aeb5d3;

@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/variables-dark';
@import 'bootstrap/scss/maps';
@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/utilities';
